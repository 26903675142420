import { SET_CURRENT_PAGE, SET_NUM_PAGES, SET_PDF_PAGINATED, SET_ZOOM_LEVEL, } from "./actions";
export const initialPDFState = {
    defaultZoomLevel: 1,
    zoomLevel: 1,
    zoomJump: 0.1,
    paginated: true,
    numPages: 0,
    currentPage: 1,
};
export const reducer = (state = initialPDFState, action) => {
    switch (action.type) {
        case SET_ZOOM_LEVEL: {
            const { value } = action;
            return Object.assign(Object.assign({}, state), { zoomLevel: value });
        }
        case SET_PDF_PAGINATED: {
            const { value } = action;
            return Object.assign(Object.assign({}, state), { paginated: value });
        }
        case SET_NUM_PAGES: {
            const { value } = action;
            return Object.assign(Object.assign({}, state), { numPages: value });
        }
        case SET_CURRENT_PAGE: {
            const { value } = action;
            return Object.assign(Object.assign({}, state), { currentPage: value });
        }
        default:
            return state;
    }
};
