{
    "documentNavInfo": "Dokument {{ currentFileNo }} av {{ allFilesCount }}",
    "noRendererMessage": "Ingen renderare för filtypen: {{{ fileType }}}",
    "downloadButtonLabel": "Ladda ner",
    "brokenFile": "Filen är trasig. Var vänlig kontrollera den på din maskin.",
    "msgPluginRecipients": "Mottagare",
    "msgPluginSender": "Avsändare",
    "pdfPluginLoading": "Laddar...",
    "pdfPluginPageNumber": "Sida {{ currentPage }}/{{ allPagesCount }}"
}
