import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import styled from "styled-components";
import { dataURLFileLoader } from "../../utils/fileLoaders";
const HTMLRenderer = ({ mainState: { currentDocument } }) => {
    useEffect(() => {
        const b64String = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileData;
        const bodyBase64 = (b64String === null || b64String === void 0 ? void 0 : b64String.replace("data:text/html;base64,", "")) || "";
        const body = window.atob(bodyBase64);
        const iframeCont = document.getElementById("html-body");
        const iframe = (iframeCont === null || iframeCont === void 0 ? void 0 : iframeCont.contentWindow) && iframeCont.contentWindow;
        if (!iframe)
            return;
        const iframeDoc = iframe.document;
        iframeDoc.open();
        iframeDoc.write(`${body}`);
        iframeDoc.close();
    }, [currentDocument]);
    return (_jsx(Container, { id: "html-renderer", children: _jsx(BodyIFrame, { id: "html-body", sandbox: "allow-same-origin" }) }));
};
export default HTMLRenderer;
HTMLRenderer.fileTypes = ["htm", "html", "text/htm", "text/html"];
HTMLRenderer.weight = 0;
HTMLRenderer.fileLoader = dataURLFileLoader;
const Container = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
`;
const BodyIFrame = styled.iframe `
  height: 100%;
  padding: 15px;
  margin: 20px 0 20px 0;
  border: 1px solid ${(props) => props.theme.secondary};
`;
