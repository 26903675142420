import { useContext, useEffect, useState } from "react";
import { DocViewerContext } from "../store/DocViewerProvider";
export const useRendererSelector = () => {
    const { state: { currentDocument, pluginRenderers }, } = useContext(DocViewerContext);
    const [CurrentRenderer, setCurrentRenderer] = useState();
    useEffect(() => {
        if (!currentDocument)
            return;
        if (!currentDocument.fileType) {
            setCurrentRenderer(undefined);
            return;
        }
        const matchingRenderers = [];
        pluginRenderers === null || pluginRenderers === void 0 ? void 0 : pluginRenderers.forEach((r) => {
            if (currentDocument.fileType === undefined)
                return;
            if (r.fileTypes.indexOf(currentDocument.fileType) >= 0) {
                matchingRenderers.push(r);
            }
        });
        const [SelectedRenderer] = matchingRenderers.sort((a, b) => b.weight - a.weight);
        if (SelectedRenderer && SelectedRenderer !== undefined) {
            setCurrentRenderer(() => SelectedRenderer);
        }
        else {
            setCurrentRenderer(null);
        }
    }, [currentDocument, pluginRenderers]);
    return { CurrentRenderer };
};
