{
    "documentNavInfo": "Documento {{ currentFileNo }} di {{ allFilesCount }}",
    "noRendererMessage": "Nessun renderer per il tipo di file: {{{ fileType }}}",
    "downloadButtonLabel": "Scarica file",
    "brokenFile": "Il tuo file è danneggiato. Controllalo sul tuo computer.",
    "msgPluginRecipients": "Destinatari",
    "msgPluginSender": "Mittente",
    "pdfPluginLoading": "Caricamento in corso...",
    "pdfPluginPageNumber": "Pagina {{ currentPage }}/{{ allPagesCount }}"
}
