{
    "documentNavInfo": "Document {{ currentFileNo }} de {{ allFilesCount }}",
    "noRendererMessage": "Aucun moteur de rendu pour le type de fichier : {{{ fileType }}}",
    "downloadButtonLabel": "Télécharger le fichier",
    "brokenFile": "Votre fichier est cassé. Veuillez le vérifier sur votre machine.",
    "msgPluginRecipients": "Destinataires",
    "msgPluginSender": "Expéditeur",
    "pdfPluginLoading": "Chargement...",
    "pdfPluginPageNumber": "Page {{ currentPage }}/{{ allPagesCount }}"
}
