import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import styled, { keyframes } from "styled-components";
import { setRendererRect } from "../store/actions";
import { getFileName } from "../utils/getFileName";
import { useDocumentLoader } from "../hooks/useDocumentLoader";
import { useWindowSize } from "../hooks/useWindowSize";
import { LinkButton } from "./common";
import { LoadingIcon } from "./icons";
import { LoadingTimeout } from "./LoadingTimout";
import { useTranslation } from "../hooks/useTranslation";
const Contents = ({ documents, documentLoading, config, currentDocument, fileName, CurrentRenderer, state, t, }) => {
    var _a, _b, _c;
    if (!documents.length) {
        return _jsx("div", { id: "no-documents" });
    }
    else if (documentLoading) {
        if (config && ((_a = config === null || config === void 0 ? void 0 : config.loadingRenderer) === null || _a === void 0 ? void 0 : _a.overrideComponent)) {
            const OverrideComponent = config.loadingRenderer.overrideComponent;
            return (_jsx(LoadingTimeout, { children: _jsx(OverrideComponent, { document: currentDocument, fileName: fileName }) }));
        }
        return (_jsx(LoadingTimeout, { children: _jsx(LoadingContainer, { id: "loading-renderer", "data-testid": "loading-renderer", children: _jsx(LoadingIconContainer, { children: _jsx(LoadingIcon, { color: "#444", size: 40 }) }) }) }));
    }
    else {
        if (CurrentRenderer) {
            return _jsx(CurrentRenderer, { mainState: state });
        }
        else if (CurrentRenderer === undefined) {
            return null;
        }
        else {
            if (config && ((_b = config === null || config === void 0 ? void 0 : config.noRenderer) === null || _b === void 0 ? void 0 : _b.overrideComponent)) {
                const OverrideComponent = config.noRenderer.overrideComponent;
                return (_jsx(OverrideComponent, { document: currentDocument, fileName: fileName }));
            }
            return (_jsxs("div", { id: "no-renderer", "data-testid": "no-renderer", children: [t("noRendererMessage", {
                        fileType: (_c = currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileType) !== null && _c !== void 0 ? _c : "",
                    }), _jsx(DownloadButton, { id: "no-renderer-download", href: currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.uri, download: currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.uri, children: t("downloadButtonLabel") })] }));
        }
    }
};
export const ProxyRenderer = () => {
    var _a;
    const { state, dispatch, CurrentRenderer } = useDocumentLoader();
    const { documents, documentLoading, currentDocument, config } = state;
    const size = useWindowSize();
    const { t } = useTranslation();
    const containerRef = useCallback((node) => {
        node && dispatch(setRendererRect(node === null || node === void 0 ? void 0 : node.getBoundingClientRect()));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size, dispatch]);
    const fileName = getFileName(currentDocument, ((_a = config === null || config === void 0 ? void 0 : config.header) === null || _a === void 0 ? void 0 : _a.retainURLParams) || false);
    return (_jsx(Container, { id: "proxy-renderer", "data-testid": "proxy-renderer", ref: containerRef, children: _jsx(Contents, { state,
            documents,
            documentLoading,
            config,
            currentDocument,
            fileName,
            CurrentRenderer,
            t }) }));
};
const Container = styled.div `
  display: flex;
  flex: 1;
`;
const LoadingContainer = styled.div `
  display: flex;
  flex: 1;
  height: 75px;
  align-items: center;
  justify-content: center;
`;
const spinAnim = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const LoadingIconContainer = styled.div `
  animation-name: ${spinAnim};
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;
const DownloadButton = styled(LinkButton) `
  width: 130px;
  height: 30px;
  background-color: ${(props) => props.theme.primary};
  @media (max-width: 768px) {
    width: 125px;
    height: 25px;
  }
`;
