export const SET_ALL_DOCUMENTS = "SET_ALL_DOCUMENTS";
export const SET_DOCUMENT_LOADING = "SET_DOCUMENT_LOADING";
export const NEXT_DOCUMENT = "NEXT_DOCUMENT";
export const PREVIOUS_DOCUMENT = "PREVIOUS_DOCUMENT";
export const UPDATE_CURRENT_DOCUMENT = "UPDATE_CURRENT_DOCUMENT";
export const SET_RENDERER_RECT = "SET_RENDERER_RECT";
export const SET_MAIN_CONFIG = "SET_MAIN_CONFIG";
export const setAllDocuments = (documents, initialActiveDocument) => ({
    type: SET_ALL_DOCUMENTS,
    documents,
    initialActiveDocument,
});
export const setDocumentLoading = (value) => ({
    type: SET_DOCUMENT_LOADING,
    value,
});
export const nextDocument = () => ({ type: NEXT_DOCUMENT });
export const previousDocument = () => ({
    type: PREVIOUS_DOCUMENT,
});
export const updateCurrentDocument = (document) => ({ type: UPDATE_CURRENT_DOCUMENT, document });
export const setRendererRect = (rect) => ({
    type: SET_RENDERER_RECT,
    rect,
});
export const setMainConfig = (config) => ({
    type: SET_MAIN_CONFIG,
    config,
});
