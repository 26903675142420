{
    "documentNavInfo": "Documento {{ currentFileNo }} de {{ allFilesCount }}",
    "noRendererMessage": "Nenhum renderizador para o tipo de arquivo: {{{ fileType }}}",
    "downloadButtonLabel": "Baixar arquivo",
    "brokenFile": "Seu arquivo está quebrado. Por favor, verifique-o em sua máquina.",
    "msgPluginRecipients": "Destinatários",
    "msgPluginSender": "Remetente",
    "pdfPluginLoading": "Carregando...",
    "pdfPluginPageNumber": "Página {{ currentPage }}/{{ allPagesCount }}"
}
