import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import PDFPages from "./components/pages/PDFPages";
import PDFControls from "./components/PDFControls";
import { PDFProvider } from "./state";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`).toString();
const PDFRenderer = ({ mainState }) => {
    return (_jsx(PDFProvider, { mainState: mainState, children: _jsxs(Container, { id: "pdf-renderer", "data-testid": "pdf-renderer", children: [_jsx(PDFControls, {}), _jsx(PDFPages, {})] }) }));
};
export default PDFRenderer;
PDFRenderer.fileTypes = ["pdf", "application/pdf"];
PDFRenderer.weight = 0;
const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;

  /* width */
  &::-webkit-scrollbar {
    ${(props) => {
    return props.theme.disableThemeScrollbar ? "" : "width: 10px";
}};
  }
  /* Track */
  &::-webkit-scrollbar-track {
    /* background: ${(props) => props.theme.secondary}; */
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.tertiary};
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.primary};
  }
`;
