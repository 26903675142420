import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState, } from "react";
import { DocViewerContext } from "../store/DocViewerProvider";
export const LoadingTimeout = ({ children }) => {
    var _a, _b;
    const { state } = useContext(DocViewerContext);
    const { config } = state;
    const [shouldLoadingRender, setShouldLoadingRender] = useState(((_a = config === null || config === void 0 ? void 0 : config.loadingRenderer) === null || _a === void 0 ? void 0 : _a.showLoadingTimeout) === false);
    useEffect(() => {
        var _a;
        setTimeout(() => {
            setShouldLoadingRender(true);
        }, typeof ((_a = config === null || config === void 0 ? void 0 : config.loadingRenderer) === null || _a === void 0 ? void 0 : _a.showLoadingTimeout) === "number"
            ? config.loadingRenderer.showLoadingTimeout
            : 500);
    }, [(_b = config === null || config === void 0 ? void 0 : config.loadingRenderer) === null || _b === void 0 ? void 0 : _b.showLoadingTimeout]);
    if (!shouldLoadingRender) {
        return null;
    }
    return _jsx(_Fragment, { children: children });
};
