{
    "documentNavInfo": "ファイル {{ currentFileNo }} / {{ allFilesCount }}",
    "noRendererMessage": "ファイルタイプに対応したレンダラーはありません: {{ fileType }}",
    "downloadButtonLabel": "ダウンロード",
    "brokenFile": "ファイルが壊れています。あなたのマシンでファイルを確認してください。",
    "msgPluginRecipients": "受信者",
    "msgPluginSender": "送信者",
    "pdfPluginLoading": "ローティング中...",
    "pdfPluginPageNumber": "ページ {{ currentPage }}/{{ allPagesCount }}"
}
