import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import styled from "styled-components";
import { DocViewerContext } from "../store/DocViewerProvider";
import { getFileName } from "../utils/getFileName";
export const FileName = () => {
    var _a, _b;
    const { state: { config, currentDocument }, } = useContext(DocViewerContext);
    if (!currentDocument || ((_a = config === null || config === void 0 ? void 0 : config.header) === null || _a === void 0 ? void 0 : _a.disableFileName))
        return null;
    const fileName = getFileName(currentDocument, ((_b = config === null || config === void 0 ? void 0 : config.header) === null || _b === void 0 ? void 0 : _b.retainURLParams) || false);
    return (_jsx(Container, { id: "file-name", "data-testid": "file-name", children: fileName }));
};
const Container = styled.div `
  flex: 1;
  text-align: left;
  color: ${(props) => props.theme.textPrimary};
  font-weight: bold;
  margin: 0 10px;
  overflow: hidden;
`;
