import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const VideoRenderer = ({ mainState: { currentDocument } }) => {
    if (!currentDocument)
        return null;
    return (_jsx(Container, { id: "video-renderer", children: _jsx(Video, { controls: true, src: currentDocument.uri }) }));
};
export default VideoRenderer;
VideoRenderer.fileTypes = ["video/mp4", "video/quicktime", "video/x-msvideo"];
VideoRenderer.weight = 0;
const Container = styled.div `
  width: 100%;
`;
const Video = styled.video `
  width: 100%;
  height: 100%;
  border: 0;
`;
