import { useContext, useEffect } from "react";
import { DocViewerContext } from "../store/DocViewerProvider";
import { setDocumentLoading, updateCurrentDocument, } from "../store/actions";
import { defaultFileLoader, } from "../utils/fileLoaders";
import { useRendererSelector } from "./useRendererSelector";
/**
 * Custom Hook for loading the current document into context
 */
export const useDocumentLoader = () => {
    const { state, dispatch } = useContext(DocViewerContext);
    const { currentFileNo, currentDocument, prefetchMethod } = state;
    const { CurrentRenderer } = useRendererSelector();
    const documentURI = (currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.uri) || "";
    useEffect(() => {
        if (!currentDocument || currentDocument.fileType !== undefined)
            return;
        const controller = new AbortController();
        const { signal } = controller;
        fetch(documentURI, {
            method: prefetchMethod || documentURI.startsWith("blob:") ? "GET" : "HEAD",
            signal,
            headers: state === null || state === void 0 ? void 0 : state.requestHeaders,
        })
            .then((response) => {
            const contentTypeRaw = response.headers.get("content-type");
            const contentTypes = (contentTypeRaw === null || contentTypeRaw === void 0 ? void 0 : contentTypeRaw.split(";")) || [];
            const contentType = contentTypes.length ? contentTypes[0] : undefined;
            dispatch(updateCurrentDocument(Object.assign(Object.assign({}, currentDocument), { fileType: contentType || undefined })));
        })
            .catch((error) => {
            if ((error === null || error === void 0 ? void 0 : error.name) !== "AbortError") {
                throw error;
            }
        });
        return () => {
            controller.abort();
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFileNo, documentURI, currentDocument]);
    useEffect(() => {
        var _a;
        if (!currentDocument || CurrentRenderer === undefined)
            return;
        const controller = new AbortController();
        const { signal } = controller;
        const fileLoaderComplete = (fileReader) => {
            if (!currentDocument || !fileReader) {
                dispatch(setDocumentLoading(false));
                return;
            }
            const updatedDocument = Object.assign({}, currentDocument);
            if (fileReader.result !== null) {
                updatedDocument.fileData = fileReader.result;
            }
            dispatch(updateCurrentDocument(updatedDocument));
            dispatch(setDocumentLoading(false));
        };
        const loaderFunctionProps = {
            documentURI,
            signal,
            fileLoaderComplete,
            headers: state === null || state === void 0 ? void 0 : state.requestHeaders,
        };
        if (CurrentRenderer === null) {
            dispatch(setDocumentLoading(false));
        }
        else if (CurrentRenderer.fileLoader !== undefined) {
            (_a = CurrentRenderer.fileLoader) === null || _a === void 0 ? void 0 : _a.call(CurrentRenderer, loaderFunctionProps);
        }
        else {
            defaultFileLoader(loaderFunctionProps);
        }
        return () => {
            controller.abort();
        };
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [CurrentRenderer, currentFileNo]);
    return { state, dispatch, CurrentRenderer };
};
