export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL";
export const setZoomLevel = (value) => ({
    type: SET_ZOOM_LEVEL,
    value,
});
export const SET_PDF_PAGINATED = "SET_PDF_PAGINATED";
export const setPDFPaginated = (value) => ({
    type: SET_PDF_PAGINATED,
    value,
});
export const SET_NUM_PAGES = "SET_NUM_PAGES";
export const setNumPages = (value) => ({
    type: SET_NUM_PAGES,
    value,
});
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const setCurrentPage = (value) => ({
    type: SET_CURRENT_PAGE,
    value,
});
