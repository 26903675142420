import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "../../hooks/useTranslation";
import { arrayBufferFileLoader } from "../../utils/fileLoaders";
import ImageProxyRenderer from "../image";
import { parseTIFF } from "./tiffToCanvas";
const TIFFRenderer = (props) => {
    const { mainState: { currentDocument }, } = props;
    const { t } = useTranslation();
    const [loadedCanvas, setLoadedCanvas] = useState(false);
    const [corruptedFile, setCorruptedFile] = useState(false);
    useEffect(() => {
        if (!currentDocument || loadedCanvas)
            return;
        const canvas = document.getElementById("tiff-img");
        try {
            canvas && parseTIFF(currentDocument.fileData, canvas);
            setLoadedCanvas(true);
        }
        catch (error) {
            setCorruptedFile(true);
        }
    }, [currentDocument, loadedCanvas]);
    if (corruptedFile) {
        return (_jsx(ImageProxyRenderer, Object.assign({}, props, { children: _jsx("div", { children: t("brokenFile") }) })));
    }
    return (_jsx(ImageProxyRenderer, Object.assign({}, props, { children: _jsx(Canvas, { id: "tiff-img" }) })));
};
TIFFRenderer.fileTypes = ["tif", "tiff", "image/tif", "image/tiff"];
TIFFRenderer.weight = 0;
TIFFRenderer.fileLoader = arrayBufferFileLoader;
export default TIFFRenderer;
const Canvas = styled.canvas `
  max-width: 95%;
  max-height: 95%;
`;
