import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer, } from "react";
import { initialPDFState, reducer, } from "./reducer";
const PDFContext = createContext({ state: initialPDFState, dispatch: () => null });
const PDFProvider = ({ children, mainState, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [state, dispatch] = useReducer(reducer, Object.assign(Object.assign({}, initialPDFState), { defaultZoomLevel: (_c = (_b = (_a = mainState.config) === null || _a === void 0 ? void 0 : _a.pdfZoom) === null || _b === void 0 ? void 0 : _b.defaultZoom) !== null && _c !== void 0 ? _c : initialPDFState.defaultZoomLevel, zoomLevel: (_f = (_e = (_d = mainState.config) === null || _d === void 0 ? void 0 : _d.pdfZoom) === null || _e === void 0 ? void 0 : _e.defaultZoom) !== null && _f !== void 0 ? _f : initialPDFState.zoomLevel, zoomJump: (_j = (_h = (_g = mainState.config) === null || _g === void 0 ? void 0 : _g.pdfZoom) === null || _h === void 0 ? void 0 : _h.zoomJump) !== null && _j !== void 0 ? _j : initialPDFState.zoomJump, paginated: ((_k = mainState.config) === null || _k === void 0 ? void 0 : _k.pdfVerticalScrollByDefault)
            ? false
            : initialPDFState.paginated, mainState }));
    return (_jsx(PDFContext.Provider, { value: { state, dispatch }, children: children }));
};
export { PDFContext, PDFProvider };
