export const getFileName = (document, retainURLParams) => {
    var _a;
    if (!document) {
        return "";
    }
    let fileName = "";
    if (document.fileName) {
        fileName = document.fileName;
    }
    else {
        fileName = document.uri || "";
        fileName = decodeURI(fileName);
        if (!retainURLParams) {
            fileName = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split("?")) === null || _a === void 0 ? void 0 : _a[0];
        }
        const splitURL = fileName === null || fileName === void 0 ? void 0 : fileName.split("/");
        if (splitURL.length) {
            fileName = splitURL[splitURL.length - 1];
        }
    }
    return fileName;
};
