import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Page } from "react-pdf";
import styled from "styled-components";
import { useTranslation } from "../../../../hooks/useTranslation";
import { PDFContext } from "../../state";
const PDFSinglePage = ({ pageNum }) => {
    const { state: { mainState, paginated, zoomLevel, numPages, currentPage }, } = useContext(PDFContext);
    const { t } = useTranslation();
    const rendererRect = (mainState === null || mainState === void 0 ? void 0 : mainState.rendererRect) || null;
    const _pageNum = pageNum || currentPage;
    return (_jsxs(PageWrapper, { id: "pdf-page-wrapper", last: _pageNum >= numPages, children: [!paginated && (_jsx(PageTag, { id: "pdf-page-info", children: t("pdfPluginPageNumber", {
                    currentPage: _pageNum,
                    allPagesCount: numPages,
                }) })), _jsx(Page, { pageNumber: _pageNum || currentPage, scale: zoomLevel, height: ((rendererRect === null || rendererRect === void 0 ? void 0 : rendererRect.height) || 100) - 100, width: ((rendererRect === null || rendererRect === void 0 ? void 0 : rendererRect.width) || 100) - 100, loading: t("pdfPluginLoading") })] }));
};
export default PDFSinglePage;
const PageWrapper = styled.div `
  margin: 20px 0;
`;
const PageTag = styled.div `
  padding: 0 0 10px 10px;
  color: ${(props) => props.theme.textTertiary};
  font-size: 14px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
