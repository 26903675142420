{
    "documentNavInfo": "Document {{ currentFileNo }} of {{ allFilesCount }}",
    "noRendererMessage": "No renderer for file type: {{{ fileType }}}",
    "downloadButtonLabel": "Download file",
    "brokenFile": "Your file is broken. Please check it on your machine.",
    "msgPluginRecipients": "Recipients",
    "msgPluginSender": "Sender",
    "pdfPluginLoading": "Loading...",
    "pdfPluginPageNumber": "Page {{ currentPage }}/{{ allPagesCount }}"
}
