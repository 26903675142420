{
    "documentNavInfo": "Документ {{ currentFileNo }} из {{ allFilesCount }}",
    "noRendererMessage": "Данный тип файла не поддерживается рендером: {{{ fileType }}}",
    "downloadButtonLabel": "Скачать файл",
    "brokenFile": "Ваш файл сломан. Пожалуйста, проверьте его на своём комьютере.",
    "msgPluginRecipients": "Получатели",
    "msgPluginSender": "Отправитель",
    "pdfPluginLoading": "Загрузка...",
    "pdfPluginPageNumber": "Страница {{ currentPage }}/{{ allPagesCount }}"
}
