import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import styled from "styled-components";
import { Button } from "../../../components/common";
import { PDFContext } from "../state";
import { setCurrentPage } from "../state/actions";
import { NextPDFNavIcon, PrevPDFNavIcon } from "./icons";
import { useTranslation } from "../../../hooks/useTranslation";
const PDFPagination = () => {
    const { state: { currentPage, numPages }, dispatch, } = useContext(PDFContext);
    const { t } = useTranslation();
    return (_jsxs(Container, { id: "pdf-pagination", children: [_jsx(PageNavButtonLeft, { id: "pdf-pagination-prev", onClick: () => dispatch(setCurrentPage(currentPage - 1)), disabled: currentPage === 1, children: _jsx(PrevPDFNavIcon, { color: "#000", size: "50%" }) }), _jsx(PageTag, { id: "pdf-pagination-info", children: t("pdfPluginPageNumber", {
                    currentPage,
                    allPagesCount: numPages,
                }) }), _jsx(PageNavButtonRight, { id: "pdf-pagination-next", onClick: () => dispatch(setCurrentPage(currentPage + 1)), disabled: currentPage >= numPages, children: _jsx(NextPDFNavIcon, { color: "#000", size: "50%" }) })] }));
};
export default PDFPagination;
const Container = styled.div `
  display: flex;
  align-items: center;
`;
const PageNavButtonLeft = styled(Button) `
  width: 30px;
  height: 30px;
  margin: 0 5px;

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;
const PageNavButtonRight = styled(PageNavButtonLeft) `
  margin: 0 20px 0 5px;
`;
const PageTag = styled.div `
  color: ${(props) => props.theme.textPrimary};
  font-size: 14px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
