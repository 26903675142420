import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import styled from "styled-components";
import { DocViewerContext } from "../store/DocViewerProvider";
import { nextDocument, previousDocument } from "../store/actions";
import { DocumentNav } from "./DocumentNav";
import { FileName } from "./FileName";
export const HeaderBar = () => {
    var _a, _b, _c;
    const { state, dispatch } = useContext(DocViewerContext);
    const { config } = state;
    if ((_a = config === null || config === void 0 ? void 0 : config.header) === null || _a === void 0 ? void 0 : _a.disableHeader)
        return null;
    const override = (_c = (_b = config === null || config === void 0 ? void 0 : config.header) === null || _b === void 0 ? void 0 : _b.overrideComponent) === null || _c === void 0 ? void 0 : _c.call(_b, state, () => dispatch(previousDocument()), () => dispatch(nextDocument()));
    if (override) {
        return override;
    }
    else {
        return (_jsxs(Container, { id: "header-bar", "data-testid": "header-bar", children: [_jsx(FileName, {}), _jsx(DocumentNav, {})] }));
    }
};
const Container = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  padding: 0 10px;
  background-color: ${(props) => props.theme.primary};
  font-size: 16px;
  min-height: 50px;

  @media (max-width: 768px) {
    min-height: 30px;
    padding: 5px;
    font-size: 10px;
  }
`;
