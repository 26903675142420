import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import papaparse from "papaparse";
import { textFileLoader } from "../../utils/fileLoaders";
const CSVRenderer = ({ mainState: { currentDocument, config }, }) => {
    const [rows, setRows] = useState([]);
    useEffect(() => {
        var _a, _b;
        if (currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileData) {
            const parseResult = papaparse.parse(currentDocument.fileData, {
                delimiter: (_a = config === null || config === void 0 ? void 0 : config.csvDelimiter) !== null && _a !== void 0 ? _a : ",",
            });
            if (!((_b = parseResult.errors) === null || _b === void 0 ? void 0 : _b.length) && parseResult.data) {
                setRows(parseResult.data);
            }
        }
    }, [currentDocument, config === null || config === void 0 ? void 0 : config.csvDelimiter]);
    if (!rows.length) {
        return null;
    }
    return (_jsx(Container, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: rows[0].map((column) => (_jsx("th", { children: column }, column))) }) }), _jsx("tbody", { children: rows.slice(1, rows.length).map((row) => (_jsx("tr", { children: row.map((column) => (_jsx("td", { children: column }, column))) }, row.join("")))) })] }) }));
};
export default CSVRenderer;
CSVRenderer.fileTypes = ["csv", "text/csv"];
CSVRenderer.weight = 0;
CSVRenderer.fileLoader = textFileLoader;
const Container = styled.div `
  width: 100%;
`;
const Table = styled.table `
  width: 100%;
  text-align: left;

  th,
  td {
    padding: 5px 10px;

    &:empty {
      display: none;
    }
  }
`;
