{
    "documentNavInfo": "مستند {{ currentFileNo }} من {{ allFilesCount }}",
    "noRendererMessage": "{{{ fileType }}} : لا يمكننا عرض هذا النوع من الملفات",
    "downloadButtonLabel": "تحميل الملف",
    "brokenFile": "الملف تالف، يرجى التحقق منه على جهازك الخاص.",
    "msgPluginRecipients": "المستلمين",
    "msgPluginSender": "المرسل",
    "pdfPluginLoading": "تحميل ...",
    "pdfPluginPageNumber": " {{ allPagesCount }} \\ {{ currentPage }} صفحة "
}
