import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const MSDocRenderer = ({ mainState: { currentDocument } }) => {
    if (!currentDocument)
        return null;
    return (_jsx(Container, { id: "msdoc-renderer", children: _jsx(IFrame, { id: "msdoc-iframe", title: "msdoc-iframe", src: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(currentDocument.uri)}`, frameBorder: "0" }) }));
};
export default MSDocRenderer;
const MSDocFTMaps = {
    odt: ["odt", "application/vnd.oasis.opendocument.text"],
    doc: ["doc", "application/msword"],
    docx: [
        "docx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/octet-stream",
    ],
    xls: ["xls", "application/vnd.ms-excel"],
    xlsx: [
        "xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    ppt: ["ppt", "application/vnd.ms-powerpoint"],
    pptx: [
        "pptx",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
};
MSDocRenderer.fileTypes = [
    ...MSDocFTMaps.odt,
    ...MSDocFTMaps.doc,
    ...MSDocFTMaps.docx,
    ...MSDocFTMaps.xls,
    ...MSDocFTMaps.xlsx,
    ...MSDocFTMaps.ppt,
    ...MSDocFTMaps.pptx,
];
MSDocRenderer.weight = 0;
MSDocRenderer.fileLoader = ({ fileLoaderComplete }) => fileLoaderComplete();
const Container = styled.div `
  width: 100%;
`;
const IFrame = styled.iframe `
  width: 100%;
  height: 100%;
  border: 0;
`;
