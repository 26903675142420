import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import styled from "styled-components";
import { Button, LinkButton } from "../../../components/common";
import { PDFContext } from "../state";
import { setPDFPaginated, setZoomLevel } from "../state/actions";
import { useTranslation } from "../../../hooks/useTranslation";
import { DownloadPDFIcon, ResetZoomPDFIcon, TogglePaginationPDFIcon, ZoomInPDFIcon, ZoomOutPDFIcon, } from "./icons";
import PDFPagination from "./PDFPagination";
const PDFControls = () => {
    const { t } = useTranslation();
    const { state: { mainState, paginated, zoomLevel, numPages, zoomJump, defaultZoomLevel, }, dispatch, } = useContext(PDFContext);
    const currentDocument = (mainState === null || mainState === void 0 ? void 0 : mainState.currentDocument) || null;
    return (_jsxs(Container, { id: "pdf-controls", children: [paginated && numPages > 1 && _jsx(PDFPagination, {}), (currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileData) && (_jsx(DownloadButton, { id: "pdf-download", href: currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileData, download: (currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileName) || (currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.uri), title: t("downloadButtonLabel"), children: _jsx(DownloadPDFIcon, { color: "#000", size: "75%" }) })), _jsx(ControlButton, { id: "pdf-zoom-out", onMouseDown: () => dispatch(setZoomLevel(zoomLevel - zoomJump)), children: _jsx(ZoomOutPDFIcon, { color: "#000", size: "80%" }) }), _jsx(ControlButton, { id: "pdf-zoom-in", onMouseDown: () => dispatch(setZoomLevel(zoomLevel + zoomJump)), children: _jsx(ZoomInPDFIcon, { color: "#000", size: "80%" }) }), _jsx(ControlButton, { id: "pdf-zoom-reset", onMouseDown: () => dispatch(setZoomLevel(defaultZoomLevel)), disabled: zoomLevel === defaultZoomLevel, children: _jsx(ResetZoomPDFIcon, { color: "#000", size: "70%" }) }), numPages > 1 && (_jsx(ControlButton, { id: "pdf-toggle-pagination", onMouseDown: () => dispatch(setPDFPaginated(!paginated)), children: _jsx(TogglePaginationPDFIcon, { color: "#000", size: "70%", reverse: paginated }) }))] }));
};
export default PDFControls;
const Container = styled.div `
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: flex-end;
  padding: 8px;
  background-color: ${(props) => props.theme.tertiary};
  box-shadow: 0px 2px 3px #00000033;

  @media (max-width: 768px) {
    padding: 6px;
  }
`;
const ControlButton = styled(Button) `
  width: 30px;
  height: 30px;
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;
const DownloadButton = styled(LinkButton) `
  width: 30px;
  height: 30px;
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;
