import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable */
import { useContext, useEffect } from "react";
import { Document } from "react-pdf";
import styled from "styled-components";
import { useTranslation } from "../../../../hooks/useTranslation";
import { PDFContext } from "../../state";
import { setNumPages } from "../../state/actions";
import { initialPDFState } from "../../state/reducer";
import { PDFAllPages } from "./PDFAllPages";
import PDFSinglePage from "./PDFSinglePage";
const PDFPages = () => {
    const { state: { mainState, paginated }, dispatch, } = useContext(PDFContext);
    const { t } = useTranslation();
    const currentDocument = (mainState === null || mainState === void 0 ? void 0 : mainState.currentDocument) || null;
    useEffect(() => {
        dispatch(setNumPages(initialPDFState.numPages));
    }, [currentDocument]);
    if (!currentDocument || currentDocument.fileData === undefined)
        return null;
    return (_jsx(DocumentPDF, { file: currentDocument.fileData, onLoadSuccess: ({ numPages }) => dispatch(setNumPages(numPages)), loading: _jsx("span", { children: t("pdfPluginLoading") }), children: paginated ? _jsx(PDFSinglePage, {}) : _jsx(PDFAllPages, {}) }));
};
const DocumentPDF = styled(Document) `
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;
export default PDFPages;
