import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const ImageProxyRenderer = (props) => {
    const { mainState: { currentDocument }, children, } = props;
    if (!currentDocument)
        return null;
    return (_jsx(Container, Object.assign({ id: "image-renderer" }, props, { children: children || (_jsx(Img, { id: "image-img", src: currentDocument.fileData })) })));
};
export default ImageProxyRenderer;
ImageProxyRenderer.fileTypes = [];
ImageProxyRenderer.weight = 0;
const Container = styled.div `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const Img = styled.img `
  max-width: 95%;
  max-height: 95%;
`;
