{
    "documentNavInfo": "Dokument {{ currentFileNo }} od {{ allFilesCount }}",
    "noRendererMessage": "Ne postoji pregledač za tip fajla: {{ fileType }}",
    "downloadButtonLabel": "Preuzimanje fajla",
    "brokenFile": "Vaš fajl nije dobar. Molimo Vas da probate da ga otvorite na vašem računaru.",
    "msgPluginRecipients": "Primaoci",
    "msgPluginSender": "Pošiljalac",
    "pdfPluginLoading": "Učitavanje...",
    "pdfPluginPageNumber": "Strana {{ currentPage }}/{{ allPagesCount }}"
}
