import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useReducer, useImperativeHandle, forwardRef, } from "react";
import { defaultLanguage, locales } from "../i18n";
import { nextDocument, previousDocument, setAllDocuments, setMainConfig, updateCurrentDocument, } from "./actions";
import { initialState, mainStateReducer, } from "./mainStateReducer";
const DocViewerContext = createContext({ state: initialState, dispatch: () => null });
const DocViewerProvider = forwardRef((props, ref) => {
    var _a;
    const { children, documents, config, pluginRenderers, prefetchMethod, requestHeaders, initialActiveDocument, language, activeDocument, onDocumentChange, } = props;
    const [state, dispatch] = useReducer(mainStateReducer, Object.assign(Object.assign({}, initialState), { documents: documents || [], currentDocument: documents && documents.length
            ? initialActiveDocument
                ? initialActiveDocument
                : documents[0]
            : undefined, config,
        pluginRenderers,
        prefetchMethod,
        requestHeaders, currentFileNo: initialActiveDocument
            ? (_a = documents.findIndex((doc) => doc === initialActiveDocument)) !== null && _a !== void 0 ? _a : 0
            : 0, language: language && locales[language] ? language : defaultLanguage, activeDocument,
        onDocumentChange }));
    useEffect(() => {
        dispatch(setAllDocuments(documents, initialActiveDocument));
        config && dispatch(setMainConfig(config));
    }, [documents, config, initialActiveDocument]);
    useEffect(() => {
        if (activeDocument) {
            dispatch(updateCurrentDocument(activeDocument));
        }
    }, [activeDocument]);
    useImperativeHandle(ref, () => ({
        prev() {
            dispatch(previousDocument());
        },
        next() {
            dispatch(nextDocument());
        },
    }), [dispatch]);
    return (_jsx(DocViewerContext.Provider, { value: { state, dispatch }, children: children }));
});
export { DocViewerContext, DocViewerProvider };
