import { NEXT_DOCUMENT, PREVIOUS_DOCUMENT, SET_ALL_DOCUMENTS, SET_DOCUMENT_LOADING, SET_MAIN_CONFIG, SET_RENDERER_RECT, UPDATE_CURRENT_DOCUMENT, } from "./actions";
import { defaultLanguage } from "../i18n";
export const initialState = {
    currentFileNo: 0,
    documents: [],
    documentLoading: true,
    currentDocument: undefined,
    rendererRect: undefined,
    config: {},
    pluginRenderers: [],
    language: defaultLanguage,
};
export const mainStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_DOCUMENTS: {
            const { documents, initialActiveDocument } = action;
            return Object.assign(Object.assign({}, state), { documents, currentDocument: initialActiveDocument
                    ? initialActiveDocument
                    : documents[0] || null, currentFileNo: initialActiveDocument && documents.includes(initialActiveDocument)
                    ? documents.indexOf(initialActiveDocument)
                    : initialState.currentFileNo });
        }
        case SET_DOCUMENT_LOADING: {
            const { value } = action;
            return Object.assign(Object.assign({}, state), { documentLoading: value });
        }
        case NEXT_DOCUMENT: {
            if (state.currentFileNo >= state.documents.length - 1)
                return state;
            const nextDocumentNo = state.currentFileNo + 1;
            if (state.onDocumentChange) {
                state.onDocumentChange(state.documents[nextDocumentNo]);
            }
            return Object.assign(Object.assign({}, state), { currentFileNo: nextDocumentNo, currentDocument: state.documents[nextDocumentNo], documentLoading: true });
        }
        case PREVIOUS_DOCUMENT: {
            if (state.currentFileNo <= 0)
                return state;
            const prevDocumentNo = state.currentFileNo - 1;
            if (state.onDocumentChange) {
                state.onDocumentChange(state.documents[prevDocumentNo]);
            }
            return Object.assign(Object.assign({}, state), { currentFileNo: state.currentFileNo - 1, currentDocument: state.documents[prevDocumentNo], documentLoading: true });
        }
        case UPDATE_CURRENT_DOCUMENT: {
            const { document } = action;
            return Object.assign(Object.assign({}, state), { currentDocument: document, currentFileNo: state.documents.findIndex((doc) => doc.uri === document.uri) });
        }
        case SET_RENDERER_RECT: {
            const { rect } = action;
            return Object.assign(Object.assign({}, state), { rendererRect: rect });
        }
        case SET_MAIN_CONFIG: {
            const { config } = action;
            return Object.assign(Object.assign({}, state), { config });
        }
        default:
            return state;
    }
};
