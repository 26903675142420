{
    "documentNavInfo": "Documento {{ currentFileNo }} de {{ allFilesCount }}",
    "noRendererMessage": "No hay procesador para el tipo de archivo: {{{ fileType }}}",
    "downloadButtonLabel": "Descargar archivo",
    "brokenFile": "Tu archivo está roto. Compruébalo en tu máquina.",
    "msgPluginRecipients": "Destinatarios",
    "msgPluginSender": "Remitente",
    "pdfPluginLoading": "Cargando...",
    "pdfPluginPageNumber": "Página {{ currentPage }}/{{ allPagesCount }}"
}
