import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from "react";
import { PDFContext } from "../../state";
import PDFSinglePage from "./PDFSinglePage";
export const PDFAllPages = () => {
    const { state: { numPages }, } = useContext(PDFContext);
    const PagesArray = [];
    for (let i = 0; i < numPages; i++) {
        PagesArray.push(_jsx(PDFSinglePage, { pageNum: i + 1 }, i + 1));
    }
    return _jsx(_Fragment, { children: PagesArray });
};
