import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { textFileLoader } from "../../utils/fileLoaders";
const TXTRenderer = ({ mainState: { currentDocument } }) => {
    return (_jsx(Container, { id: "txt-renderer", children: currentDocument === null || currentDocument === void 0 ? void 0 : currentDocument.fileData }));
};
export default TXTRenderer;
TXTRenderer.fileTypes = ["txt", "text/plain"];
TXTRenderer.weight = 0;
TXTRenderer.fileLoader = textFileLoader;
const Container = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;
