import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "core-js/proposals/promise-with-resolvers";
import { forwardRef, memo } from "react";
import styled, { ThemeProvider } from "styled-components";
import { HeaderBar } from "./components/HeaderBar";
import { ProxyRenderer } from "./components/ProxyRenderer";
import { defaultTheme } from "./defaultTheme";
import { DocViewerRenderers } from "./renderers";
import { DocViewerProvider } from "./store/DocViewerProvider";
const DocViewer = forwardRef((props, ref) => {
    const { documents, theme } = props;
    if (!documents) {
        throw new Error("Please provide an array of documents to DocViewer!");
    }
    return (_jsx(DocViewerProvider, Object.assign({ ref: ref, pluginRenderers: DocViewerRenderers }, props, { children: _jsx(ThemeProvider, { theme: theme ? Object.assign(Object.assign({}, defaultTheme), theme) : defaultTheme, children: _jsxs(Container, { id: "react-doc-viewer", "data-testid": "react-doc-viewer", className: props.className, style: props.style, children: [_jsx(HeaderBar, {}), _jsx(ProxyRenderer, {})] }) }) })));
});
export default memo(DocViewer);
const Container = styled.div `
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: 100%;
`;
